import {Theme} from '@mui/material/styles';
import {cloneElement, useContext, useMemo} from 'react';
import {
	CreateButton,
	ExportButton,
	FilterButton,
	FilterContext,
	ListActionsProps,
	TopToolbar,
	sanitizeListRestProps,
	useListContext,
	useResourceContext,
	useResourceDefinition
} from 'react-admin';

const CustomListActions = (props: ListActionsProps) => {
	const {className, filters: filtersProp, hasCreate: _, ...rest} = props;
	const {sort, displayedFilters, filterValues, exporter, showFilter, total} =
		useListContext(props);
	const resource = useResourceContext(props);
	const {hasCreate} = useResourceDefinition(props);
	const filters = useContext(FilterContext) || filtersProp;
	return useMemo(
		() => (
			<TopToolbar
				className={className}
				{...sanitizeListRestProps(rest)}
				sx={(theme: Theme) => ({
					'& .add-filter': {
						border: '1px solid',
						borderColor: theme.palette.primary.main
					}
				})}
			>
				{filtersProp
					? cloneElement(filtersProp, {
							resource,
							showFilter,
							displayedFilters,
							filterValues,
							context: 'button'
					  })
					: filters && <FilterButton />}
				{hasCreate && <CreateButton variant="contained" />}
				{exporter !== false && (
					<ExportButton
						disabled={total === 0}
						resource={resource}
						sort={sort}
						filterValues={filterValues}
					/>
				)}
			</TopToolbar>
		),
		/* eslint-disable react-hooks/exhaustive-deps */
		[
			resource,
			displayedFilters,
			filterValues,
			filtersProp,
			showFilter,
			filters,
			total,
			className,
			sort,
			exporter,
			hasCreate
		]
	);
};

export default CustomListActions;
