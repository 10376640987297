import {Box} from '@mui/material';
import {Legend} from 'recharts';
import {COLORS} from '../models/types';

interface LegendData {
	name: string;
	value: number;
}

interface ChartLegendProps {
	legends: LegendData[];
	colors?: {
		[key: string]: string;
	};
}

const ChartLegend = ({legends, colors}: ChartLegendProps) => {
	const minColumnLength = 20;

	const getLegendsColumns = (legends: LegendData[]) => {
		if (legends.length < minColumnLength) {
			return [legends];
		}
		return [
			legends.slice(0, Math.ceil(legends.length / 2)),
			legends.slice(Math.ceil(legends.length / 2), legends.length)
		];
	};

	return (
		<Box
			sx={{
				position: 'relative',
				margin: '40px 0 0',
				display: 'grid',
				gridTemplateColumns: 'repeat(2, 1fr)',
				maxWidth: 'max-content',
				columnGap: '1rem'
			}}
		>
			{getLegendsColumns(legends).map((data, key) => (
				<Legend
					key={key}
					wrapperStyle={{
						position: 'relative',
						fontSize: '0.85rem',
						maxWidth: 'max-content'
					}}
					align="right"
					layout="vertical"
					payload={data.map(({name}, index) => ({
						value: name,
						type: 'rect',
						id: `${index}`,
						color: colors?.[name] ?? COLORS[index % COLORS.length]
					}))}
				/>
			))}
		</Box>
	);
};

export default ChartLegend;
