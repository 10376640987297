import {Box, Typography} from '@mui/material';
import ChartRenderer from '../../charts/components/ChartRenderer';
import {
	ChartSizes,
	GraphicTypes,
	IndicatorStats,
	NumberTuple
} from '../../charts/models/types';
import {Indicator} from '../../resources/indicators/types';
import ErrorMessage from './ErrorMessage';

interface IndicatorChartProps {
	indicatorId: Indicator['id'];
	indicatorGraphicType: Indicator['graphical_representation'];
	stats: IndicatorStats;
	title: string;
	domain: NumberTuple | null;
}

const IndicatorChart = ({
	stats,
	title,
	indicatorGraphicType,
	indicatorId,
	domain
}: IndicatorChartProps) => (
	<Box sx={{'svg': {overflow: 'visible'}}}>
		<Typography
			component="h4"
			sx={{
				textAlign: 'center',
				fontSize: '1rem',
				marginBottom: '1rem'
			}}
		>
			{title}
		</Typography>

		{!!stats?.error ? (
			<ErrorMessage text={stats.error} size="small" />
		) : (
			<ChartRenderer
				size={ChartSizes.medium}
				idTitle={`indicator-${indicatorId}`}
				graphicType={indicatorGraphicType}
				data={stats}
				domain={domain}
				legend={
					!(GraphicTypes.bar_gradient_colors === indicatorGraphicType)
				}
			/>
		)}
	</Box>
);

export default IndicatorChart;
