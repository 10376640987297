import DomainIcon from '@mui/icons-material/Domain';
import {
	Box,
	Button,
	DialogActions,
	FormControl,
	FormHelperText,
	Grid,
	Theme,
	Typography
} from '@mui/material';
import {stringify} from 'query-string';
import {
	AutocompleteArrayInput,
	BooleanInput,
	Form,
	NumberInput,
	ReferenceArrayInput,
	useRedirect,
	useTranslate
} from 'react-admin';
import {FieldValues} from 'react-hook-form';
import {Corporation} from '../../resources/corporations/types';
import {formatDateISO} from './utils';

interface CompareDialogPanelFormProps {
	corporation: Corporation;
	dates: {
		start: Date;
		end: Date;
	};
}

const CompareDialogPanelForm = (props: CompareDialogPanelFormProps) => {
	const {corporation, dates} = props;
	const translate = useTranslate();
	const redirect = useRedirect();

	const getAverage = (
		value: number | null,
		defaultValue: number,
		operator: 'add' | 'subs'
	) => {
		if (!value) {
			return operator === 'add'
				? Math.floor(defaultValue * (1 + 0.2))
				: Math.floor(defaultValue * (1 - 0.2));
		}

		if (operator === 'add') {
			return Math.floor(value * (1 + 0.2));
		}
		return Math.floor(value * (1 - 0.2));
	};

	const handleSubmit = (fieldValues: FieldValues) => {
		const query = {
			start: formatDateISO(dates.start),
			end: formatDateISO(dates.end),
			...fieldValues
		};
		const queryString = stringify(query);
		redirect(`/corporations/${corporation.id}/compare-panel?${queryString}`);
	};

	return (
		<Grid container alignItems="center" sx={{margin: '0 auto 1rem'}}>
			<Box>
				<DomainIcon
					sx={(theme: Theme) => ({
						color: theme.palette.primary.main,
						mr: 1
					})}
					aria-hidden="true"
				/>
			</Box>
			<Box>
				<Typography
					component="h2"
					sx={(theme: Theme) => ({
						fontSize: '1.2rem',
						fontWeight: '500',
						color: theme.palette.text.secondary
					})}
				>
					{translate('corporation.compare.dialog.panel_establishements')}
				</Typography>
			</Box>
			<Form onSubmit={handleSubmit}>
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
				>
					<Grid
						container
						justifyContent="flex-start"
						alignItems="center"
						flexDirection="row"
						columnGap="2rem"
					>
						<FormHelperText
							id="number-students-helper"
							sx={{fontSize: '1.1rem'}}
						>
							{translate(
								'corporation.compare.dialog.number_students_helper'
							)}
						</FormHelperText>
						<FormControl
							sx={{
								display: 'inline'
							}}
						>
							<NumberInput
								source=""
								label={translate(
									'corporation.compare.dialog.number_students_start_label'
								)}
								id="min-students"
								defaultValue={getAverage(
									corporation.students,
									500,
									'subs'
								)}
								name="min_students"
								size="small"
								type="number"
								sx={{maxWidth: '8rem', margin: '0'}}
							/>
						</FormControl>
						<FormControl
							sx={{
								display: 'inline'
							}}
						>
							<NumberInput
								source=""
								label={translate(
									'corporation.compare.dialog.number_students_end_label'
								)}
								id="max-students"
								defaultValue={getAverage(
									corporation.students,
									500,
									'add'
								)}
								name="max_students"
								size="small"
								type="number"
								sx={{maxWidth: '8rem', margin: '0'}}
							/>
						</FormControl>
					</Grid>
					<Grid
						container
						justifyContent="flex-start"
						alignItems="center"
						flexDirection="row"
						columnGap="2rem"
					>
						<FormHelperText id="regions-helper" sx={{fontSize: '1.1rem'}}>
							{translate('corporation.compare.dialog.regions_helper')}
						</FormHelperText>
						<FormControl
							sx={{
								display: 'inline',
								flex: '1'
							}}
						>
							<ReferenceArrayInput
								source="region_id"
								reference="regions"
							>
								<AutocompleteArrayInput
									label={translate(
										'corporation.compare.dialog.regions_label'
									)}
									name="regions_ids"
									optionText="name"
									suggestionLimit={10}
									debounce={500}
									filterToQuery={(searchText) => ({
										name: `%${searchText}%`
									})}
									size="small"
									sx={{flex: '0 0 100%', maxWidth: '100%'}}
								/>
							</ReferenceArrayInput>
						</FormControl>
					</Grid>
					<Grid
						container
						justifyContent="flex-start"
						alignItems="baseline"
						flexDirection="row"
						columnGap="0.6rem"
					>
						<FormHelperText
							id="school-levels-helper"
							sx={{fontSize: '1.1rem'}}
						>
							{translate(
								'corporation.compare.dialog.school_levels_helper'
							)}
						</FormHelperText>
						<FormControl
							sx={{
								display: 'inline'
							}}
						>
							<BooleanInput
								source=""
								label={translate(
									'corporation.compare.dialog.school_levels_label'
								)}
								defaultValue={true}
								defaultChecked={true}
								name="same_activities"
								id="same_activities"
								size="small"
								sx={{
									margin: '1rem 0rem 0 0',
									'& .MuiTypography-root': {
										fontSize: '0.8rem',
										opacity: '0.9'
									}
								}}
							/>
						</FormControl>
					</Grid>
					<Grid
						container
						justifyContent="flex-start"
						alignItems="center"
						flexDirection="row"
						columnGap="1rem"
					>
						<FormHelperText
							id="agreement-helper"
							sx={{fontSize: '1.1rem'}}
						>
							{translate('corporation.compare.dialog.agreement_helper')}
						</FormHelperText>
						<FormControl
							sx={{
								display: 'inline',
								flex: '1'
							}}
						>
							<ReferenceArrayInput
								source="agreement_id"
								reference="agreements"
							>
								<AutocompleteArrayInput
									label={translate(
										'corporation.compare.dialog.agreement_label'
									)}
									name={'agreements_ids'}
									source="agreements"
									suggestionLimit={10}
									debounce={500}
									filterToQuery={(searchText) => ({
										label: `%${searchText}%`
									})}
									optionText="label"
									size="small"
								/>
							</ReferenceArrayInput>
						</FormControl>
					</Grid>
					<Grid
						container
						justifyContent="flex-start"
						alignItems="center"
						flexDirection="row"
						columnGap="1rem"
					>
						<FormHelperText id="payroll-helper" sx={{fontSize: '1.1rem'}}>
							{translate('corporation.compare.dialog.payroll_helper')}
						</FormHelperText>
						<FormControl
							sx={{
								display: 'inline'
							}}
						>
							<NumberInput
								source=""
								id="min-payroll"
								label={translate(
									'corporation.compare.dialog.payroll_start_label'
								)}
								name="min_payroll"
								size="small"
								type="number"
								sx={{maxWidth: '5.5rem'}}
							/>
						</FormControl>
						<FormControl
							sx={{
								display: 'inline'
							}}
						>
							<NumberInput
								source=""
								id="max-payroll"
								label={translate(
									'corporation.compare.dialog.payroll_end_label'
								)}
								name="max_payroll"
								size="small"
								type="number"
								sx={{maxWidth: '5.5rem'}}
							/>
						</FormControl>
					</Grid>
				</Grid>
				<DialogActions sx={{alignSelf: 'flex-end'}}>
					<Button variant="contained" type="submit">
						{translate(
							'corporation.compare.dialog.submit.establishement'
						)}
					</Button>
				</DialogActions>
			</Form>
		</Grid>
	);
};

export default CompareDialogPanelForm;
