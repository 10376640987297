import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import {FilledTextFieldProps, TextField, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {debounce} from 'debounce';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslate} from 'react-admin';
import {Link} from 'react-router-dom';
import dataProvider from '../../providers/data';
import {Corporation} from '../../resources/corporations/types';
import './CorporationSwitcher.css';

interface CorporationSwitcherProps {
	corporation: Corporation;
}

const CorporationSwitcher = ({corporation}: CorporationSwitcherProps) => {
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [name, setName] = useState('');
	const [hasOnlyOneEstablishment, setHasOnlyOneEstablishment] = useState(true);
	const [total, setTotal] = useState<number | null>(null);
	const [corporations, setCorporations] = useState<Corporation[]>([]);
	const translate = useTranslate();

	const fetchCorporations = useRef(async (name = '') => {
		setIsLoading(true);
		const result = await dataProvider.getList<Corporation>('corporations', {
			pagination: {page: 1, perPage: 5},
			filter: {name},
			sort: {
				field: 'name',
				order: 'ASC'
			}
		});
		const total = result?.total ?? 1;
		setTotal(total);
		setCorporations(result.data);
		setIsLoading(false);
		return total;
	}).current;
	const debouncedFetchCorporations = useRef(
		debounce(fetchCorporations, 500)
	).current;

	useEffect(() => {
		fetchCorporations().then((total) => {
			setHasOnlyOneEstablishment(total === 1);
		});
	}, [fetchCorporations]);

	const handleNameFilterChange: FilledTextFieldProps['onChange'] = async (
		e
	) => {
		const name = e.target.value;
		setName(name);
		debouncedFetchCorporations(name);
	};

	const toggleList = useCallback(
		() => setIsCollapsed((isCollapsed) => !isCollapsed),
		[setIsCollapsed]
	);

	if (hasOnlyOneEstablishment && !isLoading) {
		return (
			<Typography
				component="h1"
				sx={(theme: Theme) => ({
					fontSize: '2rem',
					fontWeight: '500',
					lineHeight: 1.2,
					color: theme.palette.text.secondary,
					display: 'flex',
					alignItems: 'center',
					mt: '0.5rem'
				})}
			>
				{corporation?.name}
			</Typography>
		);
	}

	return (
		<div
			className={`CorporationSwitcher ${isCollapsed ? 'is-collapsed' : ''}`}
		>
			<button
				type="button"
				className="CorporationSwitcher-button"
				aria-expanded={isCollapsed}
				aria-controls="corporations-list"
				onClick={toggleList}
			>
				<Typography
					component="h1"
					sx={(theme: Theme) => ({
						fontSize: '2rem',
						fontWeight: '500',
						lineHeight: 1.2,
						color: theme.palette.text.secondary,
						display: 'flex',
						alignItems: 'center'
					})}
				>
					{corporation?.name}
					<ArrowDropDownOutlinedIcon
						fontSize="large"
						sx={{
							transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
							transition: 'transform 0.2s linear'
						}}
					/>
				</Typography>
			</button>
			{isCollapsed && (
				<div
					id="corporations-list"
					className="CorporationSwitcher-listWrapper"
				>
					<form
						onSubmit={(event) => {
							event.preventDefault();
						}}
					>
						<TextField
							id="name"
							type="text"
							value={name}
							onChange={handleNameFilterChange}
							label={translate('corporation.switcher.label')}
							title={translate('corporation.switcher.title')}
							variant="filled"
							size="medium"
							autoComplete="off"
							fullWidth
						/>
					</form>

					<Typography
						component="p"
						sx={{fontSize: '0.8rem'}}
						aria-live="polite"
					>
						{!!total && total > 0 ? (
							<>
								{translate('corporation.switcher.results.total', total)}
								{translate(
									'corporation.switcher.results.displayed',
									corporations?.length
								)}
							</>
						) : (
							translate('corporation.switcher.results.none')
						)}
					</Typography>

					<ul className="CorporationSwitcher-list">
						{corporations?.map(({id, name}) => (
							<li
								key={`corporations-list-${id}`}
								className="CorporationSwitcher-listItem"
							>
								<Link
									aria-current={
										id === corporation.id ? 'true' : undefined
									}
									replace
									to={`/corporations/${id}/detail`}
									className="CorporationSwitcher-link"
									onClick={toggleList}
								>
									<span>{name}</span>
									{id === corporation.id && (
										<span className="CorporationSwitcher-linkHint">
											(établissement actif)
										</span>
									)}
								</Link>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default CorporationSwitcher;
