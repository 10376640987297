import {
	BarChart,
	Cell,
	Bar as RechartsBar,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import {BarStats, ChartSizes, NumberTuple} from '../models/types';
import {getHeightInPixels} from '../utils/utils';
import ChartLegend from './ChartLegend';
import {useLabelsColor} from './useLabelsColor';

interface BarProps {
	data: BarStats['all'];
	size: ChartSizes;
	idTitle: string;
	domain?: NumberTuple | null;
	legend: boolean;
}

const Bar = ({data, size, idTitle, domain, legend}: BarProps) => {
	const colors = useLabelsColor();

	const formattedData = Object.values(data).map((value) => ({
		name: value.label,
		value: Math.floor(value.rule * 10) / 10
	}));

	return (
		<>
			<div style={{height: getHeightInPixels(size)}}>
				<ResponsiveContainer>
					<BarChart data={formattedData} maxBarSize={40}>
						<YAxis
							domain={domain ?? [0, 'auto']}
							allowDecimals={false}
							interval={'preserveStartEnd'}
							allowDataOverflow={true}
							padding={{top: 2, bottom: 2}}
						/>
						<XAxis hide dataKey="name" />
						<Tooltip
							contentStyle={{maxWidth: '15rem', whiteSpace: 'unset'}}
						/>
						<RechartsBar
							dataKey="value"
							fill="#0f91ab"
							name="Total"
							minPointSize={2}
							isAnimationActive={false}
						>
							{formattedData.map(({name}, index) => (
								<Cell
									cursor="pointer"
									fill={
										colors?.bar?.[idTitle]
											? colors?.bar?.[idTitle]?.[name]
											: colors?.bar_gradient_colors?.[idTitle]?.[
													name
											  ]
									}
									key={`cell-${index}`}
								/>
							))}
						</RechartsBar>
					</BarChart>
				</ResponsiveContainer>
			</div>
			{legend && (
				<ChartLegend
					legends={formattedData}
					colors={
						colors?.bar?.[idTitle] ??
						colors?.bar_gradient_colors?.[idTitle]
					}
				/>
			)}
		</>
	);
};

export default Bar;
