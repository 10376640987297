type StatData = {
	label: string;
	rule: number;
};

export type IndicatorError = {error?: string};

export type PieStats = {
	all: {
		[key: string]: StatData;
	};
};

export type BarStats = PieStats;

export type StackedBarStats = {
	[key: string]: Record<string, Record<string, StatData>>;
};

export type AreaStats = {
	[key: string]: Record<string, number> | number | Record<string, StatData>;
};

export type LineStats = AreaStats;

export type NumberStats = {
	all: number;
};

export type TableStats = {
	[key: string]: number;
};

export type IndicatorStats =
	| (NumberStats & IndicatorError)
	| (PieStats & IndicatorError)
	| (BarStats & IndicatorError)
	| (AreaStats & IndicatorError)
	| (LineStats & IndicatorError)
	| (TableStats & IndicatorError)
	| (StackedBarStats & IndicatorError);

export type Stats = {
	[indicatorId: number]: IndicatorStats;
};

export enum GraphicTypes {
	currency = 'currency',
	percent = 'percent',
	pie = 'pie',
	bar = 'bar',
	bar_gradient_colors = 'bar_gradient_colors',
	line = 'line',
	table = 'table',
	area = 'area',
	people = 'people',
	mixBar = 'mix_bar',
	mixBar_2 = 'mix_bar_2',
	values_table = 'values_table'
}

export const COLORS = [
	'#FE862F',
	'#58CFF4',
	'#DB3A34',
	'#17889E',
	'#7BD78F',
	'#732DAA',
	'#FFB5AD',
	'#7B4F2F',
	'#FED43E',
	'#08A045',
	'#3A405A',
	'#C39F85'
];

export const COLORS_GRADIENT = [
	'#74e2ec',
	'#35cadb',
	'#19aec1',
	'#17889e',
	'#1a7084',
	'#1e5b6c',
	'#1d4d5c',
	'#0e323e'
];

export const COLOR_END_OF_CONTRACT = '#C39F85';

export enum ChartSizes {
	small = 'small',
	medium = 'medium',
	large = 'large'
}

interface Payload {
	payload: {
		key: string;
		value: number;
		percentNumber: number;
		percent: string;
	};
	cx: string;
	cy: string;
	stroke: string;
	fill: string;
	key: string;
	value: number;
	percentNumber: number;
	percent: string;
}

interface TooltipPayload {
	name: number;
	value: number;
	payload: Payload;
	dataKey: string;
}

interface TooltipPosition {
	x: number;
	y: number;
}

interface Points {
	x: number;
	y: number;
}

export type PieLabelType = {
	cx: number;
	cy: number;
	stroke: string;
	fill: string;
	percent: string;
	name: number;
	tooltipPayload: TooltipPayload[];
	midAngle: number;
	middleRadius: number;
	tooltipPosition: TooltipPosition;
	payload: Payload;
	key: string;
	value: number;
	percentNumber: number;
	points: Array<Points>;
	innerRadius: number;
	outerRadius: number;
	maxRadius: number;
	startAngle: number;
	endAngle: number;
	paddingAngle: number;
	index: number;
	textAnchor: string;
	x: number;
	y: number;
};

export type NumberTuple = [number, number];

export type ChartsLabelsColorsStoreType = {
	pie: {
		[key: string]: Record<string, string>;
	};
	bar: {
		[key: string]: Record<string, string>;
	};
	bar_gradient_colors: {
		[key: string]: Record<string, string>;
	};
	mix_bar: {
		[key: string]: Record<string, string>;
	};
	mix_bar_2: {
		[key: string]: Record<string, string>;
	};
};
