import {Box} from '@mui/material';
import {
	Bar,
	BarChart,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import {
	COLOR_END_OF_CONTRACT,
	ChartSizes,
	NumberTuple,
	StackedBarStats
} from '../models/types';
import {getHeightInPixels} from '../utils/utils';
import {useLabelsColor} from './useLabelsColor';

interface MixedBarProps {
	data: StackedBarStats;
	size: ChartSizes;
	idTitle: string;
	domain?: NumberTuple | null;
}

const MixBar = ({data, size, idTitle, domain}: MixedBarProps) => {
	const colors = useLabelsColor();

	const entries = Object.entries(data);
	const formattedData = entries
		.flatMap(([key, value]) => {
			const mixedBar = Object.assign(
				{},
				...Object.keys(value).map((k) => {
					if (
						k !== 'Fins de contrats' &&
						k !== 'Autre nature de contrat, convention, mandat' &&
						value[k] !== null
					) {
						return Object.entries(value[k])
							.map(([_, {label, rule}]) =>
								Object.fromEntries([
									[label, hasDecimal(rule) ? rule.toFixed(2) : rule]
								])
							)
							.reduce((prev, curr) => {
								const key = Object.keys(curr)[0];
								return {
									...prev,
									...{[`${k} ${key}`.trim()]: curr[key]}
								};
							}, {});
					}
					return {};
				})
			);
			const bar = Object.assign(
				{},
				...Object.keys(value).map((k) => {
					if (
						k === 'Fins de contrats' ||
						k === 'Autre nature de contrat, convention, mandat'
					) {
						if (value[k]) {
							const sum = Object.values(value[k] || {})
								.map(({rule}) => rule)
								.reduce((acc, rule) => acc + rule, 0);

							return {
								[`${k}`.trim()]: parseFloat(sum.toFixed(2))
							};
						}
					}
					return {};
				})
			);

			return {
				...(key !== 'all' && {
					name: key,
					...mixedBar,
					...bar
				})
			};
		})
		.filter((value) => JSON.stringify(value) !== '{}');

	const label = formattedData
		.flatMap((value) => Object.keys(value))
		.map((key) => key);
	const legends = [...new Set(label)];

	return (
		<>
			<div style={{height: getHeightInPixels(size)}}>
				<ResponsiveContainer>
					<BarChart data={formattedData} maxBarSize={40}>
						<YAxis
							domain={domain ?? [0, 'auto']}
							allowDecimals={false}
							interval={'preserveStartEnd'}
							allowDataOverflow={true}
						/>
						<XAxis dataKey="name" fontSize={16} />
						<Tooltip
							contentStyle={{maxWidth: '15rem', whiteSpace: 'unset'}}
						/>
						{Object.keys(
							formattedData
								.map((value) => value)
								.reduce((prev, acc) => ({...prev, ...acc}), {})
						).map(
							(key, index) =>
								key !== 'Fins de contrats' &&
								key !== 'name' && (
									<Bar
										key={index}
										stackId="mixedBar"
										dataKey={key}
										name={key}
										fill={
											colors?.mix_bar?.[idTitle]?.[
												key.replace(/\n/g, ' ')
											]
										}
										minPointSize={0}
										isAnimationActive={false}
									></Bar>
								)
						)}
						{Object.keys(
							formattedData
								.map((value) => value)
								.reduce((prev, acc) => ({...prev, ...acc}), {})
						).map(
							(key, index) =>
								key === 'Fins de contrats' && (
									<Bar
										key={index}
										dataKey={key}
										name={key}
										minPointSize={1}
										isAnimationActive={false}
										fill={COLOR_END_OF_CONTRACT}
									></Bar>
								)
						)}
					</BarChart>
				</ResponsiveContainer>
			</div>
			<Box
				sx={{
					position: 'relative',
					margin: '40px 0 0',
					display: 'grid',
					gridTemplateColumns: 'repeat(1, 1fr)',
					maxWidth: 'max-content',
					columnGap: '1rem'
				}}
			>
				{legends.map(
					(value, index) =>
						value !== 'name' && (
							<Legend
								key={index}
								wrapperStyle={{
									position: 'relative',
									fontSize: '0.85rem',
									maxWidth: 'max-content'
								}}
								align="right"
								layout="vertical"
								payload={[
									{
										value: value,
										color:
											value !== 'Fins de contrats'
												? colors?.mix_bar?.[idTitle]?.[
														value.replace(/\n/g, ' ')
												  ]
												: COLOR_END_OF_CONTRACT,
										type: 'rect',
										id: `${index}`
									}
								]}
							/>
						)
				)}
			</Box>
		</>
	);
};

export default MixBar;

function hasDecimal(number: number): boolean {
	return number !== Math.floor(number);
}
